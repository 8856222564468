@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.subaccounts-selector {
    svg {
        width: 24px;
        height: 24px;
        color: var(--brand-color);
    }

    margin: 0 var(--space);
}

.subaccounts-list {
    .options-wrapper {
        border-top: 1px solid var(--border-color);
        overflow-y: scroll;
        max-height: 300px;
    }
}
