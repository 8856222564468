@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.left-navigation {
    .MuiPaper-root {
        min-width: 170px;
        padding: var(--space) 0;
    }

    .close-icon-container {
        min-height: 64px;
        text-align: right;
        padding: 0 var(--space-3);
    }

    .menu-title {
        font-weight: var(--font-weight-b1);
        text-transform: uppercase;
        margin-bottom: var(--space-2);
        padding: 0 var(--space-3);
    }

    .link-container {
        padding: var(--space-2) var(--space-3);

        .link {
            text-decoration: none;
            width: 100%;
            display: inline-block;
            color: var(--nav-link-color);
            font-size: var(--font-size-p2);
            font-weight: var(--font-weight-n);

            &:hover,
            &:focus {
                color: var(--nav-link-color-hover);
            }
        }

        &.is-active {
            border-right: 2px solid var(--brand-color-accented);

            .link {
                font-size: var(--font-size-p1);
                font-weight: var(--font-weight-b2);
            }
        }
    }

    @media (min-width: @screen-lg) {
        display: none;
    }
}

.header-container {
    background-color: var(--background-color-accented);
    box-shadow: 0 1px 2px var(--box-shadow-color);

    .header {
        display: flex;
        align-items: center;
        width: 100%;
        height: var(--header-height);
        padding: 0;
        max-width: 1600px;
        margin: 0 auto;

        @media (min-width: @screen-sm) {
            padding: 0 calc(var(--space) / 2);
        }

        @media (min-width: @screen-lg) {
            padding: 0 var(--space-6);

            .logo {
                height: var(--header-height);
                margin-right: var(--space-10);
            }
        }

        &::before {
            content: "";
            width: var(--space-6);
            margin-right: auto;
            margin-left: 10px;
            visibility: hidden;
        }

        .link-container {
            display: none;
            height: 100%;
            padding: 0 var(--space-2);
            margin: 0 var(--space-2);
            align-items: center;

            .link {
                font-size: var(--font-size-p1);
                font-weight: var(--font-weight-b3);
                border: none;
                padding: 0;
                text-decoration: none;
                color: var(--nav-link-color);
                background-color: inherit;
                cursor: pointer;

                &:hover {
                    outline: none;
                    color: var(--nav-link-color-hover);
                }
            }

            &.is-active {
                border-bottom: 2px solid var(--brand-color-accented);

                .link {
                    font-weight: var(--font-weight-b1);
                }
            }
        }

        .right-container {
            margin-left: auto;
            display: flex;
        }

        .user-menu {
            display: none;
        }

        .navigation-icon-container {
            display: none;
        }

        &.show-items {
            &::before {
                display: none;
            }

            .user-menu {
                display: block;
            }

            .navigation-icon-container {
                display: block;
                margin-right: auto;
                width: calc(var(--space-6) + var(--space-6));

                .icon-navigation-drawer {
                    height: 24px;
                }
            }

            @media (min-width: @screen-lg) {
                justify-content: flex-start;

                .link-container {
                    display: flex;
                }

                .navigation-icon-container {
                    display: none;
                }
            }
        }
    }
}
