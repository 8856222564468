@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.delegation-icon-container {
    width: 48px;

    .delegation-icon {
        color: var(--brand-color);

        &.is-delegated {
            color: var(--brand-color-accented);
        }
    }
}

.delegation-modal {
    .users-container {
        margin-top: var(--space-2);
    }

    .MuiFormControlLabel-label {
        width: 100%;
    }

    .MuiIconButton-label {
        color: var(--brand-color);
    }

    .search-container {
        margin-top: var(--space-2);

        .search {
            width: 100%;
        }
    }

    .no-result-container {
        display: flex;
        justify-content: center;
        padding: var(--space-3) var(--space-3) var(--space);
    }

    .user {
        display: flex;
        flex-wrap: wrap;
        font-size: var(--font-size-p2);
        padding: calc(var(--space) / 2) 0;

        .user-name {
            width: 40%;
            font-weight: var(--font-weight-b2);
        }
    }
}
